<template>
    <div class="historyMeeting_container">
        <div class="historyMeeting_information_block">
            <div class="historyMeeting_user_information">
                <div class="historyMeeting_user_name">{{$store.state.common.doctorInfo.name}}</div>
                <div class="historyMeeting_hospatil">
                    <div class="historyMeeting_hospatil_name">{{$store.state.common.doctorInfo.hospital}}</div>
                    <div class="historyMeeting_hospatil_split_line"></div>
                </div>
            </div>
            <div class="historyMeeting_hospital_icon"><img :src="require('@/assets/images/icon/hospital.png')" /></div>
        </div>
        <div class="historyMeeting_listscroll">
            <listScroll ref="listScroll" :url="$urls.doctor.historyMeetList">
                <template v-slot:listItem="props">
                    <div class="historyMeeting_item_block" @click="goToMeetingDetail(props.citems)">
                        <meetItem :item="props.citems"></meetItem>
                    </div>
                </template>
            </listScroll>
        </div>
    </div>
</template>
<script>
import listScroll from '@/components/unit/listScroll'
import meetItem from "@/components/unit/meetingItem"
export default {
  name:'historyMeeting',
  data(){
      return { }
  },
  created(){
      document.getElementsByTagName("title")[0].innerHTML = "我的历史会议"
  },
  mounted(){
        this.initScroll();
  },
  components:{
      meetItem,
      listScroll
  },
  methods:{
        initScroll(){
            this.$refs.listScroll.initScroll()
        },
      
        goToMeetingDetail(data){
            console.log(data.status)
            this.$router.push({
                path:"/meetDetail",
                query:{
                    id:data.id
                }
            })
        }
  }
}
</script>
<style>
.historyMeeting_container{
    width: 100%;
    height: 100%;
    background: #f1f1f1;
    padding:5.33vw;
    /* padding: 9.2vw; */
}
.historyMeeting_information_block{
    width: 100%;
    height: 23vw;
    padding: 4vw 4vw 0 4vw;
    margin-bottom: 3.2vw;
    display: flex;
    background: #e0e0e0;
}
.historyMeeting_item_block{
    width: 100%;
    height: 23vw;
    margin-bottom: 3.2vw;
}
.historyMeeting_user_information{
    flex: 1 0 0;
    padding-bottom:4vw;
    display: flex;
    flex-direction: column;
}
.historyMeeting_user_name{
    font-size: 5vw;
    font-weight: 400;
}
.historyMeeting_hospatil{
    flex: 1 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 3.6vw;
    font-weight: 400;
}
.historyMeeting_hospatil_name{
    max-width: 60%;
}
.historyMeeting_hospatil_split_line{
    /* width: 22vw; */
    flex: 1 0 0;
    margin:0 4vw;
    border-bottom: 1px #b8b8b8 solid;
}
.historyMeeting_hospital_icon{
    flex: 20vw 0 0;
    height: 100%;
}
.historyMeeting_hospital_icon img{
    width: 100%;
    height: 100%;
}
 .historyMeeting_listscroll{
     width: 100%;
     height: 85%;
 }
</style>
