<template>
  <div class='meetingItem_container'>
        <div class="meeting_time_block">
            <div class="meeting_time_date">
                <div class="meeting_time_day">{{$tools.getDate(item.start_time,"MM月dd日")}}</div>
                <div class="meeting_time_week">{{getWeek(item.start_time)}}</div>
            </div>
            <div class="meeting_time_clock">{{$tools.getDate(item.start_time,"hh:mm")}}-{{$tools.getDate(item.end_time,"hh:mm")}}</div>
        </div>
        <div class="meeting_item_split_line"></div>   
        <div class="meeting_detail_block">
            <div class="meeting_number">{{item.meeting_number}}</div>
            <div class="meeting_title">{{item.name}}</div>
        </div>
        <div class="meeting_detail_state" :class="item.state?'state_finished':'state_watistart'">
            {{getStatus(item)}}
        </div>
  </div>
</template>
<script>
export default {
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    data(){
        return {
        }
    },
    created(){
    },
    methods:{
        getStatus(data){
            return this.$tools.getMeetStatus(data)
            // console.log(data.status)
            // if(data.status < 2&&data.is_test) {return this.$config.categary.meeting[0]}
            // if(data.status < 2) {return this.$config.categary.meeting[data.status]}
            // if(!data.sign_name) return "待签署"
            // if(!data.pay_status) return "待支付"
            // return "已支付"
        },
        getWeek(time){
            time = time.split(" ")[0]
            let date =new Date(time);
            let millisecond = this.$tools.getTime(date) - this.$tools.getTime( this.$tools.getDate() ) 
            if(millisecond==0) return "今天"
            if(millisecond==24 * 60 * 60 * 1000) return "明天"
            return `周${this.$config.categary.week[date.getDay()]}`
        },
  }
}
</script>


<style>
.meetingItem_container{
  height:100%;
  height: 23vw;
  font-size: 3.6vw;
  font-weight: 400;
  padding:4vw;
  background: #fff;
  display: flex;
  margin-bottom: 3.2vw;
  border: #e9e9e9 1px solid;
}
.meeting_time_block{
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.meeting_time_date{
    display: flex;
    justify-content: space-between;
    min-height:6.2vw;
}
.meeting_item_split_line{
    height: 100%;
    border-right: 1px #b8b8b8 solid;
    margin: 0 3vw;
}
.meeting_detail_block{
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right:2vw;
}
.meeting_title{
    margin-top: 1.5vw;
    height: 7.9vw;
    font-size: 4vw;
    line-height: 4vw;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.meeting_detail_state{
    width: 15vw;
    margin-left: 1vw;
    display: flex;
    align-items: center;
}
.state_watistart{
    color: #ed6b00;
}
.state_finished{
    color: #8dc556;
}
</style>